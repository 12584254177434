// extracted by mini-css-extract-plugin
export var $animation_name = "PigTailCta-module--$animation_name--a92d6";
export var dash = "PigTailCta-module--dash--185d9";
export var pigTailCta = "PigTailCta-module--pigTailCta--3289d";
export var pigTailCtaDark = "PigTailCta-module--pigTailCtaDark--c8f4e";
export var pigTailCtaDark__wrapper = "PigTailCta-module--pigTailCtaDark__wrapper--f0365";
export var pigTailCtaDark__wrapper__link = "PigTailCta-module--pigTailCtaDark__wrapper__link--74303";
export var pigTailCtaDark__wrapper__svg = "PigTailCta-module--pigTailCtaDark__wrapper__svg--fb2c0";
export var pigTailCta__wrapper = "PigTailCta-module--pigTailCta__wrapper--ede88";
export var pigTailCta__wrapper__link = "PigTailCta-module--pigTailCta__wrapper__link--02123";
export var pigTailCta__wrapper__svg = "PigTailCta-module--pigTailCta__wrapper__svg--e11f1";