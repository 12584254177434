import React from "react"

import * as s from "./Header.module.scss"

const CallOut = ({ className, data }) => {
  if (!data.calloutMessage || data.calloutMessage.length < 2) {
    return <></>
  }

  return (
    <div className={`${s.Callout} ${className && className}`}>
      <div className={s.Callout__inner}>
        <span className={s.Callout__message}>{data.calloutMessage}</span>
      </div>
    </div>
  )
}

export default CallOut
