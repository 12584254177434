import React from "react"

import * as l from "../SideBar.module.scss"

const Delivery = copy => {
  return (
    <>
      <div className={l.Content__header}>
        <h3>Delivery & Returns</h3>
      </div>

      <div
        className={l.Content__wysiwygContent}
        dangerouslySetInnerHTML={{
          __html: copy,
        }}
      />
    </>
  )
}

export default Delivery
