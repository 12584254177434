import React from "react"
import * as s from "./CloseBtn.module.scss"

export const CloseBtn = ({ onClick, className }) => {
  return (
    <div
      className={`${className && className} ${s.CloseBtn}`}
      onClick={onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.414 17.414">
        <g data-name="Group 371" fill="none" stroke="#000" strokeWidth="2">
          <path data-name="Line 1" d="M16.707.707l-16 16" />
          <path data-name="Line 3" d="M16.707 16.707l-16-16" />
        </g>
      </svg>
    </div>
  )
}
