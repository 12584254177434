import React from "react"

import * as s from "../Form.module.scss"

import type { Props } from "./CheckBox.interface"

import Tick from "../../../../assets/icons/tick.inline.svg"

export const CheckBox = ({
  label,
  value,
  onChange,
  error,
  className,
}: Props) => {
  return (
    <div
      className={`${error && s.CheckBox___errored} ${className && className}`}
      onClick={() => onChange && onChange(!value)}
      onKeyDown={e => e.key === "Enter" && onChange && onChange(!value)}
      role="checkbox"
      tabIndex={0}
      aria-checked={Boolean(Boolean(value))}
    >
      <div className={s.CheckBox__inner}>
        <div
          className={`${s.CheckBox__box} ${
            value === true && s.CheckBox__box___active
          }`}
        >
          <Tick />
        </div>
        {label}
      </div>
    </div>
  )
}
