export const parseLink = (link: string): string => {
  if (!link) return ""
  try {
    var url = new URL(link)
    let parsedLink = url.pathname.replace("home/", "")
    return parsedLink
  } catch (_) {
    return link
  }
}
