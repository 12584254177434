import React, { useEffect, useState } from "react"
import { Provider, useSelector } from "react-redux"
import { store } from "../state/createStore"

import Header from "../components/main/header/Header"
import AgeGate from "../components/main/age-gate/AgeGate"
import { Lightbox } from "../components/common/video/Lightbox"
import { SideBar } from "../components/main/sidebar/SideBar"
import { OldBrowserWarning } from "../components/main/old-browser-warning/OldBrowserWarning"
import { Schema } from "../components/common/schema/Schema"
import "swiper/css/bundle"

import Logo from "../assets/images/tennentsLogo.png"

import * as s from "./Index.module.scss"
import "../styles/baseline.scss"

const Index = ({ children, pageContext, data, location }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { wpPage } = data || {}

  let template = wpPage && wpPage ? wpPage.template.templateName : null 

  if (location.pathname.includes("shop")) {
    template = "Shop"
  }

  if (location.pathname.includes("terms")) {
    template = "Support"
  }

  if (location.pathname.includes("news")) {
    template = "Blog"
  }

  if (location.pathname.includes("preview")) {
    template = "Preview"
  }

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  return (
    <Provider store={store}>
      <div>
        <Lightbox />
        <OldBrowserWarning />
        <Schema
          data={{
            "@type": "Organization",
            name: "Orchard Pig",
            url: "https://orchard-pig.co.uk/",
            logo: `https://orchard-pig.co.uk/${Logo}`,
            sameAs: [
              "https://www.facebook.com/TennentsLager",
              "https://twitter.com/TennentsLager",
              "https://www.instagram.com/TennentsLager",
            ],
          }}
        />

        {template.templateName !== "Support" && <AgeGate />}

        <SideBar pageContext={pageContext} />

        <Header
          pageData={pageContext}
          template={template}
          showMenu={showMenu}
          setShowMenu={val => setShowMenu(val)}
          location={location}
        />

        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </Provider>
  )
}

export default Index

const ContentWrapper = ({ children }) => {
  const hasPassedAgeGate = useSelector(state => state.agegate.havePassed)

  return (
    <div
      className={`${s.ContentWrapper} ${
        !hasPassedAgeGate && s.ContentWrapper___ageGate
      }`}
    >
      {children}
    </div>
  )
}
