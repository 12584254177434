import React from "react"

import * as s from "./Header.module.scss"

export const Burger = ({
  active,
  scroll,
  isChildShopPage,
  setShowMenu,
  showMenu,
}) => {
  return (
    <div className={s.Burger} onClick={() => setShowMenu(!showMenu)}>
      <div
        className={`${s.Burger__inner} ${active && s.Burger___active} ${
          scroll || isChildShopPage ? s.Burger__scroll : ""
        }`}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
