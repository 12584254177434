import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

import CartRedcuer from "../ducks/cartSlice"
import SidebarRecucer from "../ducks/sidebarSlice"
import AgeGateReducer from "../ducks/ageGateSlice"
import LightboxReducer from "../ducks/lightboxSlice"
import AppReducer from "../ducks/appSlice"
import FilterProductsReducer from "../ducks/filterProductsSlice"

const reducers = combineReducers({
  app: AppReducer.reducer,
  cart: CartRedcuer.reducer,
  sidebar: SidebarRecucer.reducer,
  agegate: AgeGateReducer.reducer,
  filterProducts: FilterProductsReducer.reducer,
  lightbox: LightboxReducer.reducer,
})

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// export default store

export { store }
