import { useState, useEffect } from 'react';

/**
 * Bundle an inner-width resizing event listener into an easy-to-use hook.
 */
export default function (): number {
  const [width, setWidth] = useState(typeof window !== 'undefined' && window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(typeof window !== 'undefined' && window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}
