import React, { useRef, useEffect } from "react"
import cx from "classnames"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"

import { Nav } from "./Nav"
import { MenuFooter } from "../header/MenuFooter"

import * as s from "./Menu.module.scss"

const Menu = ({
  mainMenu,
  isOpen,
  template,
  currentPage,
  setShowMenu,
  location,
}) => {
  const _content = useRef(null)

  useEffect(() => {
    if (isOpen) {
      let doc = document.getElementsByTagName("html")[0]
      doc.style.cssText = "overflow: hidden"
      disableBodyScroll(_content.current)
    } else {
      enableBodyScroll(_content.current)
      let doc = document.getElementsByTagName("html")[0]
      doc.style.cssText = ""
    }
  }, [isOpen])

  return (
    <div
      className={cx(s.Menu, {
        [s.Menu___isOpen]: isOpen,
      })}
      ref={_content}
    >
      <div
        className={s.MenuWrapper}
        style={{ display: isOpen ? "flex" : "none" }}
      >
        <div className={s.MenuWrapper__lhs}>
          <div>
            <Nav
              mainMenuItems={mainMenu}
              currentPage={currentPage}
              template={template}
              setShowMenu={setShowMenu}
              location={location}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
