// extracted by mini-css-extract-plugin
export var $animation_name = "AgeGate-module--$animation_name--79231";
export var Cover = "AgeGate-module--Cover--da346";
export var Cover__closed = "AgeGate-module--Cover__closed--b514b";
export var ageGate = "AgeGate-module--ageGate--5c405";
export var ageGate__closed = "AgeGate-module--ageGate__closed--d8fba";
export var ageGate__confirmCTA = "AgeGate-module--ageGate__confirmCTA--35dca";
export var ageGate__inner = "AgeGate-module--ageGate__inner--ed7e5";
export var ageGate__island = "AgeGate-module--ageGate__island--3b47f";
export var ageGate__island___inner = "AgeGate-module--ageGate__island___inner--3e2a4";
export var ageGate__island___innerText = "AgeGate-module--ageGate__island___innerText--277eb";
export var ageGate__open = "AgeGate-module--ageGate__open--1b6ea";
export var ageGate__panel = "AgeGate-module--ageGate__panel--bb72a";
export var ageGate__panel___flex = "AgeGate-module--ageGate__panel___flex--f693d";
export var ageGate__panel___text = "AgeGate-module--ageGate__panel___text--651ae";
export var ageGate__tabs = "AgeGate-module--ageGate__tabs--94cc3";
export var tab_content = "AgeGate-module--tab_content--03042";