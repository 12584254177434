import React, { useState, useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "../../common/link/Link"

import { Props } from "./Cta.interface"
import * as s from "./Cta.module.scss"
import { setState } from "../../../ducks/sidebarSlice"

const Title = ({ title }) => <span className={s.Cta__title}>{title}</span>

export const Cta = ({
  className,
  title,
  link,
  onClick,
  target,
  external,
  darkBorder,
}: Props) => {
  const [sidebarOverride, setSidebarOverride] = useState(null)
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    if (link && link.includes("newsletter")) {
      setSidebarOverride("NEWSLETTER")
    }
  }, [])

  const rootClassName = `${s.Cta} ${className ? className : ""}`
  const rootOnClick = () => {
    onClick && onClick()
    sidebarOverride &&
      dispatch(
        setState({
          sidebarIsVisible: true,
          content: sidebarOverride,
        }),
      )
  }

  return sidebarOverride || !link ? (
    <div
      className={rootClassName}
      onClick={rootOnClick}
      style={{ border: darkBorder ? "1px solid black" : "1px solid white" }}
    >
      <Title title={title} />
    </div>
  ) : external ? (
    <a
      className={rootClassName}
      onClick={rootOnClick}
      href={link}
      target={target || "_blank"}
    >
      <Title title={title} />
    </a>
  ) : (
    <Link
      className={rootClassName}
      onClick={rootOnClick}
      to={`/${link}`}
      target={target ? target : undefined}
    >
      <Title title={title} />
    </Link>
  )
}
