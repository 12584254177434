import React, { useRef, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useSelector, useDispatch } from "react-redux"
import OutsideClickHandler from "react-outside-click-handler"

import { setState } from "../../../ducks/sidebarSlice"

import * as s from "./SideBar.module.scss"

import Cart from "./content/Cart"
import SizingGuide from "./content/SizingGuide"
import Delivery from "./content/Delivery"
import Newsletter from "./content/Newsletter"
import { UpSell } from "./content/UpSell"

import Cross from "../../../assets/svg/cross.inline.svg"

export const SideBar = ({ pageContext }) => {
  const { wpPage } = useStaticQuery(graphql`
    query SideBarQuery {
      wpPage(slug: { eq: "shop" }) {
        acfShop {
          helpSizingGuide
          helpDeliveryReturns
        }
      }
    }
  `)

  const dispatch = useDispatch()

  const sidebarIsVisible = useSelector(state => state.sidebar.sidebarIsVisible)
  const upsellIsVisible = useSelector(state => state.sidebar.upsellIsVisible)
  const transitioningToShopify = useSelector(
    state => state.sidebar.checkoutTransition,
  )

  const content = useSelector(state => state.sidebar.content)
  const _content = useRef(null)

  useEffect(() => {
    dispatch(
      setState({
        sidebarIsVisible: false,
        upsellIsVisible: false,
        checkoutTransition: false,
      }),
    )
  }, [pageContext])

  useEffect(() => {
    const doc = document.getElementsByTagName("html")[0]
    if (sidebarIsVisible) {
      doc.style.cssText =
        "touch-action: none; overscroll-behavior: none; -webkit-overflow-scrolling: auto; overflow: hidden;"
    } else {
      doc.style.cssText = ""
    }
  }, [sidebarIsVisible])

  const renderContentBlock = () => {
    switch (content) {
      case "CART":
        return <Cart />
        break
      case "SIZING":
        return <SizingGuide copy={wpPage.acfShop.helpSizingGuide} />
        break
      case "DELIVERY":
        return <Delivery copy={wpPage.acfShop.helpDeliveryReturns} />
        break
      case "NEWSLETTER":
        return <Newsletter />
        break
    }
  }

  return (
    <>
      {upsellIsVisible && <UpSell />}
      <div
        className={`${s.SideBar} ${sidebarIsVisible && s.SideBar___isVisible}`}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            if (sidebarIsVisible) {
              dispatch(
                setState({
                  sidebarIsVisible: false,
                }),
              )
            }
          }}
        >
          {!transitioningToShopify && (
            <div
              className={s.SideBar__closeBtn}
              onClick={() => {
                dispatch(
                  setState({
                    sidebarIsVisible: false,
                  }),
                )
              }}
            >
              <Cross />
            </div>
          )}
          <div className={s.Content} ref={_content}>
            {renderContentBlock()}
          </div>
        </OutsideClickHandler>
      </div>
      <div
        className={`${s.SideBar__cover} ${
          sidebarIsVisible && s.SideBar__cover___isVisible
        }`}
      />
    </>
  )
}
