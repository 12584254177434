import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import { parseLink } from "../../../utils/urlParser"
import useWindowWidth from "../../../hooks/window-width"

import * as s from "./Nav.module.scss"

import Arrow from "../../../assets/icons/arrow.inline.svg"

export const Nav = ({ mainMenuItems, currentPage, setShowMenu }) => {
  const isClient = typeof window === "object"
  const { menuItems } = mainMenuItems
  const [subMenu, setSubMenu] = useState(null)
  const [subMenuTitle, setSubMenuTitle] = useState(null)
  const [isSubPage, setIsSubPage] = useState(false)
  const [animate, setAnimate] = useState(false)
  const windowWidth = useWindowWidth()
  const isMobile = windowWidth < 1024

  let hoverTimeout

  const onHover = item => {
    if (!isClient) return
    if (isMobile) return

    hoverTimeout = setTimeout(() => {
      setAnimate(false)
      setTimeout(() => {
        setAnimate(true)
      }, 100)
    }, 400)
  }

  useEffect(() => {
    if (!currentPage.page) return

    if (isSubPage) {
      let tempCurrentPageSubMenu = menuItems.nodes.filter(
        item => item.url === currentPage.page.ancestors.nodes[0].uri,
      )
      setSubMenu(tempCurrentPageSubMenu[0].childItems)
      setSubMenuTitle(tempCurrentPageSubMenu[0].title)
    } else {
      let tempCurrentPageSubMenu = menuItems.nodes.filter(item =>
        item.url === currentPage.page.ancestors
          ? currentPage.page.ancestors.nodes[0].uri
          : item.url,
      )

      if (!tempCurrentPageSubMenu[0]) return

      setSubMenu(tempCurrentPageSubMenu[0].childItems)
      setSubMenuTitle(tempCurrentPageSubMenu[0].title)
    }
  }, [isSubPage])

  return (
    <div className={s.Nav}>
      <div className={`${s.NavMain} ${s.NavMain___hasActive}`}>
        <span className={s.Nav__subTitle}>Menu</span>

        <ul>
          <li>
            <Link
              onClick={() => setShowMenu(false)}
              className={s.Nav__link}
              to="/"
            >
              Home
            </Link>
          </li>

          {menuItems.nodes.map((item, idx) => {
            return (
              <li
                key={idx}
                onMouseOver={() => {
                  onHover(item)
                }}
                onMouseLeave={() => {
                  clearTimeout(hoverTimeout)
                }}
              >
                <Link
                  onClick={() => setShowMenu(false)}
                  to={parseLink(item.url)}
                  className={s.Nav__link}
                >
                  {item.label}
                  <Arrow />
                </Link>
              </li>
            )
          })}
        </ul>
      </div>

      {subMenu && (
        <div
          className={`${s.NavSub} ${s.NavSub___hasActive} ${
            animate ? s.NavSub___animate : s.NavSub___noAnimate
          }`}
        >
          <span className={s.Nav__subTitle}>{subMenuTitle}</span>

          <ul>
            {subMenu.map((item, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => {
                    setShowMenu(false)
                  }}
                >
                  <Link
                    className={s.Nav__link}
                    to={parseLink(item.url)}
                    onClick={setShowMenu(false)}
                  >
                    {item.title}
                    <Arrow />
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
