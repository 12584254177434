import React, { ReactNode } from "react"
import { Link } from "../link/Link"

import PigTail from "../../../assets/svg/PigTail.inline.svg"

import * as s from "./PigTailCta.module.scss"

interface IPigTailCta {
  to?: string
  children: ReactNode
  onClick?: () => void
  className?: string
  target?: string
  external?: boolean
  dark?: Boolean
}

export const PigTailCta = ({
  to,
  children,
  onClick,
  className,
  dark,
}: IPigTailCta) => {
  if (to && !dark) {
    return (
      <div className={`${s.pigTailCta} ${className && className}`}>
        <PigTail className={s.pigTailCta__wrapper__svg} />

        <div onClick={onClick && onClick} className={s.pigTailCta__wrapper}>
          <Link className={s.pigTailCta__wrapper__link} to={to}>
            {children}
          </Link>
        </div>
      </div>
    )
  } else if (onClick && !dark) {
    return (
      <div className={`${s.pigTailCta} ${className && className}`}>
        <PigTail className={s.pigTailCta__wrapper__svg} />

        <div onClick={onClick} className={s.pigTailCta__wrapper}>
          <div className={s.pigTailCta__wrapper__link}>{children}</div>
        </div>
      </div>
    )
  } else if (to && dark) {
    return (
      <div className={`${s.pigTailCtaDark} ${className && className}`}>
        <PigTail className={s.pigTailCtaDark__wrapper__svg} />

        <div onClick={onClick && onClick} className={s.pigTailCtaDark__wrapper}>
          <Link className={s.pigTailCtaDark__wrapper__link} to={to}>
            {children}
          </Link>
        </div>
      </div>
    )
  } else if (onClick && dark) {
    return (
      <div className={`${s.pigTailCtaDark} ${className && className}`}>
        <PigTail className={s.pigTailCtaDark__wrapper__svg} />

        <div onClick={onClick} className={s.pigTailCtaDark__wrapper}>
          <div className={s.pigTailCtaDark__wrapper__link}>{children}</div>
        </div>
      </div>
    )
  }
}
