import React from "react"

import Logo from "../../../assets/images/OrchardPigLogo.png"
import { Link } from "../../common/link/Link"

import type { Props } from "./LogoHeader.interface"

import * as s from "./LogoHeader.module.scss"

export const LogoHeader = ({ className }: Props) => {
  return (
    <div className={`${s.logoHeader} ${className && className}`}>
      <div className={s.logoHeader__logoWrapper}>
        <Link to="/">
          <img
            className={s.logoHeader__image}
            alt="Orchard Pig Logo"
            src={Logo}
          />
        </Link>
      </div>
    </div>
  )
}
