import { createSlice } from "@reduxjs/toolkit"

const app = createSlice({
  name: "sidebar",
  initialState: {
    sidebarIsVisible: false,
    upsellIsVisible: false,
    content: "CART",
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
    closeSidebar: state => {
      state.sidebarIsVisible = false
    },
    openSidebar: state => {
      state.sidebarIsVisible = true
    },
    closeUpsell: state => {
      state.upsellIsVisible = false
    },
    openUpsell: state => {
      state.upsellIsVisible = true
    },
  },
})

export const {
  setState,
  closeSidebar,
  closeUpsell,
  openUpsell,
  openSidebar,
} = app.actions

export default app
