import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import * as s from "./Upsell.module.scss"

import { Cta } from "../../../common/cta/Cta"
import toBase64String from "../../../../utils/toBase64String.ts"
import decodeShopifyProductId from "../../../../utils/decodeShopifyProductId"
import useOnClickOutside from "../../../../hooks/click-outside"
import { addToCart } from "../../../../ducks/cartSlice"
import { setAppState } from "../../../../ducks/appSlice"

import { setState, closeUpsell } from "../../../../ducks/sidebarSlice"

export const UpSell = () => {
  const dispatch = useDispatch()
  const _upsellModal = useRef()
  const closeModal = () => {
    dispatch(closeUpsell())
    dispatch(setAppState({ pageLocked: false }))
  }
  useOnClickOutside(_upsellModal, () => closeModal())
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [addedItems, setAddedItems] = useState([])
  const [continueDisabled, setContinueDisabled] = useState(false)
  const [idsOfProductsAdded, setIdsOfProductsAdded] = useState([])

  const checkout = useSelector(state => state.cart.checkout)
  const cart = useSelector(state => state.cart)
  const visible = useSelector(state => state.sidebar.upsellIsVisible)

  useEffect(() => {
    const doc = document.getElementsByTagName("html")[0]
    if (visible) {
      doc.style.cssText =
        "touch-action: none; overscroll-behavior: none; -webkit-overflow-scrolling: auto; overflow: hidden;"
    } else {
      doc.style.cssText = ""
    }
  }, [])

  useEffect(() => {
    if (!checkout || !checkout.lineItems[0]) return

    const productId = checkout.lineItems[0].variant.product.id
    const variantId = productId.includes("gid://shopify/")
      ? toBase64String(productId)
      : productId

    const currentProduct = decodeShopifyProductId(variantId)

    if (recommendedProducts.length < 1) {
      fetchData(currentProduct)
      dispatch(setAppState({ pageLocked: true }))
    }
  }, [checkout])

  if (!cart.checkout) {
    return <span></span>
  }

  const fetchData = async productId => {
    try {
      const shopifyRes = await axios.post(`/.netlify/functions/recommended`, {
        productId,
      })
      const recomendations = shopifyRes.data.products.filter(item => {
        return item.type !== "Personalised" && item.variants.length < 2
      })
      if (recomendations.length < 1) {
        openCheckout()
      } else {
        setRecommendedProducts(recomendations.slice(0, 3))
      }
    } catch (e) {
      openCheckout()
    }

    dispatch(
      setState({
        sidebarIsVisible: false,
      }),
    )
  }

  const encodeShopifyProductId = id => {
    const encoded = btoa(id)
    return encoded
  }

  const handleAddToCart = product => {
    let checkoutId = cart.checkout.id
    setAddedItems([...addedItems, product.id])
    dispatch(
      addToCart({
        product: {
          shopifyId: encodeShopifyProductId(
            `gid://shopify/ProductVariant/${product.variants[0].id}`,
          ),
          quantity: 1,
        },
        checkoutId,
      }),
    )
  }

  const openCheckout = () => {
    setContinueDisabled(true)
    dispatch(setAppState({ pageLocked: false }))
    let gaLinkerParam = null
    window.ga(function (tracker) {
      gaLinkerParam = tracker.get("linkerParam")
    })
    const queryStringSuffix = gaLinkerParam ? `&${gaLinkerParam}` : ""
    window.location.href = `${checkout.webUrl}${queryStringSuffix}`
  }

  return (
    <div className={s.UpSell}>
      <div
        ref={_upsellModal}
        className={`${s.UpSellContent} ${
          recommendedProducts.length > 0 ? s.UpSellContent___in : ""
        }`}
      >
        <div className={s.UpSellContent__header}>
          <h3>While you're here?</h3>
        </div>
        <div className={s.UpSellContent__products}>
          <div className={s.ProductList}>
            {recommendedProducts.map((product, i) => {
              return (
                <div
                  className={s.ProductList__product}
                  key={i}
                  onClick={() => {
                    trackCustomEvent({
                      category: "Cart",
                      action: "upsell product click",
                    })
                    handleAddToCart(product)
                    setIdsOfProductsAdded([...idsOfProductsAdded, i])
                  }}
                >
                  <div
                    className={`${s.ProductList__image} ${
                      idsOfProductsAdded.includes(i)
                        ? s.ProductList__imageAdded
                        : ""
                    }`}
                    style={{
                      backgroundImage: `url(${
                        product.variants[0].featured_image
                          ? product.variants[0].featured_image.src
                          : product.images[0]
                      })`,
                    }}
                  />
                  <div className={s.ProductList__title}>
                    <span>
                      {product.variants.length > 1
                        ? product.variants[0].name
                        : product.title}
                    </span>
                  </div>
                  <div className={s.ProductList__price}>
                    <span>£{(product.variants[0].price / 100).toFixed(2)}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className={s.UpSellContent__footer}>
          <div className={s.UpSellContent__footer___container}>
            <span className={s.UpSellContent__footer___containerOr}> OR</span>
            <Cta
              title={"Continue to checkout"}
              onClick={openCheckout}
              className={s.UpSellContent__Cta}
              disabled={continueDisabled}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
