import React, { useState } from "react"
import classNames from "classnames"

import type { Props } from "./TextInput.interface"

import * as s from "../Form.module.scss"

export const TextInput = ({
  placeholder,
  value,
  onChange,
  onBlur,
  onKeyDown,
  error,
  className,
  requireEmail,
}: Props) => {
  let [focused, setFocused] = useState(false)

  const inputWrapperClasses = classNames({
    [s.InputWrapper]: true,
    [s.InputWrapper___focused]: focused,
    [s.InputWrapper___errored]: error,
    [s.InputWrapper___filled]: value && !focused,
    [className]: className,
  })

  return (
    <div className={inputWrapperClasses}>
      {placeholder && <span className={s.Placeholder}>{placeholder}</span>}

      <div>
        <input
          type={requireEmail ? "email" : "text"}
          onFocus={() => setFocused(true)}
          onBlur={e => {
            setFocused(false)
            if (onBlur) {
              onBlur(e)
            }
          }}
          onChange={onChange}
          onKeyDown={e => e.key === "Enter" && onKeyDown}
          value={value}
          required={requireEmail}
        />
      </div>
    </div>
  )
}
