// extracted by mini-css-extract-plugin
export var $animation_name = "Upsell-module--$animation_name--4a1c9";
export var ProductList = "Upsell-module--ProductList--c5009";
export var ProductList__cta = "Upsell-module--ProductList__cta--ed537";
export var ProductList__image = "Upsell-module--ProductList__image--2939d";
export var ProductList__imageAdded = "Upsell-module--ProductList__imageAdded--99b67";
export var ProductList__price = "Upsell-module--ProductList__price--db947";
export var ProductList__product = "Upsell-module--ProductList__product--d589b";
export var ProductList__title = "Upsell-module--ProductList__title--066bb";
export var UpSell = "Upsell-module--UpSell--c4baa";
export var UpSellContent = "Upsell-module--UpSellContent--2f824";
export var UpSellContent__Cta = "Upsell-module--UpSellContent__Cta--42944";
export var UpSellContent___in = "Upsell-module--UpSellContent___in--bd393";
export var UpSellContent__footer = "Upsell-module--UpSellContent__footer--e9ba3";
export var UpSellContent__footer___container = "Upsell-module--UpSellContent__footer___container--9f678";
export var UpSellContent__footer___containerOr = "Upsell-module--UpSellContent__footer___containerOr--3122b";
export var UpSellContent__header = "Upsell-module--UpSellContent__header--ff78a";
export var UpSellContent__products = "Upsell-module--UpSellContent__products--d7e39";