import React from "react"
import ReactPlayer from "react-player"
import { useDispatch, useSelector } from "react-redux"
import { setState } from "../../../ducks/lightboxSlice"

import { CloseBtn } from "../closeBtn/CloseBtn"

import * as s from "./Video.module.scss"

export const Lightbox = () => {
  const dispatch = useDispatch()
  const showLightbox = useSelector(state => state.lightbox.visible)
  const url = useSelector(state => state.lightbox.url)

  const isClient = typeof window === "object"

  return (
    <div className={`${s.Lightbox} ${showLightbox ? s.Lightbox___active : ""}`}>
      <CloseBtn
        className={s.CloseBtn}
        onClick={() =>
          dispatch(
            setState({
              visible: false,
            }),
          )
        }
      />
      {showLightbox && (
        <div className={s.Lightbox__inner}>
          {isClient && (
            <ReactPlayer
              url={url || ""}
              playing
              playsinline={true}
              width={"100%"}
              height={"100%"}
            />
          )}
        </div>
      )}
    </div>
  )
}
