import React from "react"

import { Link } from "../../common/link/Link"

import { parseLink } from "../../../utils/urlParser"

import * as s from "./Header.module.scss"

export const Nav = ({
  navItems,
  offTop,
  isScrollingDown,
  location,
  isOpen,
  isChildShopPage,
}) => {
  const Scrolling = offTop || isScrollingDown || isOpen || isChildShopPage
  const isAbout =
    location.pathname === "/core-range" ||
    location.pathname === "/bottles" ||
    location.pathname === "/in-bars" ||
    location.pathname === "/juicy-pigs"

  return (
    <div className={s.Header__inner___nav}>
      <div
        className={`${s.Header__inner___navBox} ${
          location.pathname == "/" ? s.Header__inner___navBoxActive : ""
        } ${
          Scrolling && "/" == location.pathname
            ? s.Header__inner___navBoxActiveScroll
            : ""
        }`}
      >
        <Link
          className={`${s.Header__inner___navItem} ${
            Scrolling ? s.Header__inner___navItemScroll : ""
          }`}
          to="/"
        >
          Home
        </Link>
      </div>

      {navItems.nodes.map((items, idx) => {
        return (
          <React.Fragment key={idx}>
            <div
              className={`${s.Header__inner___navBox} ${
                location.pathname.includes(items.url) ||
                items.url === location.pathname ||
                (isAbout && items.url.includes("core-range"))
                  ? s.Header__inner___navBoxActive
                  : ""
              } ${
                (Scrolling && location.pathname.includes(items.url)) ||
                (Scrolling && isAbout && items.url.includes("core-range")) ||
                (Scrolling && items.url === location.pathname)
                  ? s.Header__inner___navBoxActiveScroll
                  : ""
              }`}
            >
              <Link
                className={`${s.Header__inner___navItem} ${
                  Scrolling ? s.Header__inner___navItemScroll : ""
                }`}
                to={parseLink(items.url)}
              >
                {items.label}
              </Link>
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}
