import React from "react"

import * as s from "./QuantitySelector.module.scss"

export const QuantitySelector = ({
  quantity,
  updateIncreaseQuantity,
  updateDecreaseQuantity,
  updateQuantity,
}) => {
  if (updateQuantity) {
    return (
      <div className={s.QuantitySelector}>
        <div
          className={s.QuantitySelector__button}
          onClick={() => updateQuantity(-1)}
          onKeyDown={e => e.key === "Enter" && updateQuantity(-1)}
          role="button"
          tabIndex="0"
        >
          <span>-</span>
        </div>

        <div className={s.QuantitySelector__value}>
          <span>{quantity}</span>
        </div>

        <div
          className={s.QuantitySelector__button}
          onClick={() => updateQuantity(1)}
          onKeyDown={e => e.key === "Enter" && updateQuantity(1)}
          role="button"
          tabIndex="0"
        >
          <span>+</span>
        </div>
      </div>
    )
  } else if (updateDecreaseQuantity && updateIncreaseQuantity) {
    return (
      <div className={s.QuantitySelector}>
        {quantity > 1 ? (
          <div
            className={s.QuantitySelector__button}
            onClick={() => updateDecreaseQuantity()}
            onKeyDown={e => e.key === "Enter" && updateDecreaseQuantity()}
            role="button"
            tabIndex="0"
          >
            <span>-</span>
          </div>
        ) : (
          <div
            className={`${s.QuantitySelector__button} ${s.QuantitySelector__inActive}`}
            role="button"
            tabIndex="0"
          >
            <span>-</span>
          </div>
        )}

        <div className={s.QuantitySelector__value}>
          <span>{quantity}</span>
        </div>

        <div
          className={s.QuantitySelector__button}
          onClick={() => updateIncreaseQuantity()}
          onKeyDown={e => e.key === "Enter" && updateIncreaseQuantity()}
          role="button"
          tabIndex="0"
        >
          <span>+</span>
        </div>
      </div>
    )
  }
}
