import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useDispatch, useSelector } from "react-redux"
import { checkAgeGate, acceptAgeGate } from "../../../ducks/ageGateSlice"

import { Tabs } from "./tabs/Tabs"
import { LogoHeader } from "../logo-header/LogoHeader"

import * as s from "./AgeGate.module.scss"

const AgeGate = () => {
  const { wpPage } = useStaticQuery(graphql`
    query AgeGateQuery {
      wpPage(title: { eq: "Home" }) {
        acfHome {
          heroSlides {
            image {
              sourceUrl
            }
          }
        }
      }
    }
  `)

  const dispatch = useDispatch()
  const havePassed = useSelector(state => state.agegate.havePassed)

  useEffect(() => {
    dispatch(checkAgeGate())
  }, [])

  useEffect(() => {
    if (!havePassed) {
      let doc = document.getElementsByTagName("html")[0]
      disableBodyScroll(document.body)
      doc.style.overflow = "hidden"
    }
  }, [havePassed])

  const _setAccepted = () => {
    let background = document.getElementById("ageGate")
    background.style.border = "0"
    background.style.opacity = "0"

    const timer = setTimeout(() => {
      dispatch(acceptAgeGate())
      let doc = document.getElementsByTagName("html")[0]
      enableBodyScroll(document.body)
      doc.style.overflow = "scroll"
    }, 500)

    return () => clearTimeout(timer)
  }

  const openClass = !havePassed ? s.ageGate__open : s.ageGate__closed
  const backroundImage = wpPage.acfHome.heroSlides[0].image.sourceUrl

  return (
    <div
      id="ageGate"
      className={`${s.ageGate} ${openClass}`}
      style={{ background: `url(${backroundImage}), #000` }}
    >
      <LogoHeader />

      <div className={s.ageGate__inner}>
        <div className={s.ageGate__island}>
          <div className={s.ageGate__island___inner}>
            <div>
              <p className={s.ageGate__island___innerText}>No Porkies...</p>
              <h3>Are you over 18?</h3>
            </div>

            <div className={s.ageGate__tabs}>
              <Tabs
                onTabClick={i => {
                  if (i == 0) {
                    _setAccepted()
                  }
                }}
              >
                <div id={0} label="Yes"></div>
                <div id={1} label="No">
                  <div className={s.ageGate__panel}>
                    <span className={s.ageGate__panel___text}>
                      Thanks for not telling a Porky Pie. We'll have a cider
                      waiting for you when you turn 18.
                    </span>
                  </div>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgeGate
